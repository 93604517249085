<template>
  <div>
    <div class="az-content-breadcrumb">
      <span>
        <router-link :to="{ name: 'assets-home'}">Asset Registry</router-link>
      </span>
      <span>
        <router-link :to="{ name: 'assets-catalogs'}">Catalog</router-link>
      </span>
      <span>Asset</span>
      <div class="mg-l-auto">
        <router-link :to="{ name: 'assets-catalogs'}">
          <i class="fa fa-arrow-left" />
          Back
        </router-link>
      </div>
    </div>
    <h2 class="az-content-title">Assets</h2>

    <div class="az-content-label mg-b-5">Assets</div>
    <p class="mg-b-20">An asset is an item of property owned by a person or company, regarded as having value, commitments, or legacies.</p>

    <div class="row row-sm mg-b-20">
      <div class="col-lg-3 offset-lg-9">
        <button
          class="btn btn-orange rounded w-100 font-weight-bold"
          data-target="#assetCreationConfirmationModal"
          data-toggle="modal"
          title="Add Asset"
        >
          Add Asset
        </button>
      </div>
    </div>

    <template>
      <div
        class="tab-pane-body mt-4"
        v-if="assets.length>0"
      >
        <div class="table-responsive mg-t-20">
          <table class="table table-striped">
            <thead>
              <tr>
                <th class="w-5">Index</th>
                <th class="w-20">Catalog</th>
                <th class="w-35">Asset</th>
                <th class="w-35">Owner</th>
                <th class="w-5 text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-bind:key="index"
                v-for="(asset, index) in assets"
              >
                <td>{{index+1}}</td>
                <td>{{catalog}}</td>
                <td
                  :title="asset.asset"
                  class="masked"
                >
                  <a href="javascript:void(0);"><u>{{asset.asset}}</u></a>
                </td>
                <td
                  :title="asset.owner"
                  class="masked"
                >
                  <a href="javascript:void(0);"><u>{{asset.owner}}</u></a>
                </td>
                <td class="text-center">
                  <router-link
                    :to="{name: 'viewasset', params: { assetid: asset.asset}}"
                    class="mg-r-10 text-info"
                    title="View Asset"
                  >
                    <i class="fa fa-border fa-eye" />
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div><!-- table-responsive -->
      </div><!-- tab-pane-body -->
      <div
        class="card-body card-blue"
        v-else
      >
        <h4 class="card-title text-muted text-center">No records found</h4>
      </div>

      <div
        class="row"
        v-if="assets.length>0"
      >
        <div class="col-lg-1 pd-l-0">
          <select
            class="form-control mx-sm-3"
            v-model="pageSize"
          >
            <option v-for="size in pageSizes">{{size}}</option>
          </select>
        </div>
        <div class="col-lg-2 pd-t-5 pd-l-10">
          items/page
        </div>
        <div class="col-lg-9">
          <ul class="pagination justify-content-end mg-b-0">
            <li
              @click="prev()"
              class="page-item"
            >
              <a
                class="page-link"
                href="javascript:void(0)"
              >
                <i class="icon ion-ios-arrow-back" />
              </a>
            </li>
            <template v-for="index in pages">
              <li
                :class="{'active': index === page+1 }"
                :key="index"
                @click="goToPage(index-1  )"
                class="page-item"
              >
                <a
                  class="page-link"
                  href="javascript:void(0)"
                >{{index}}</a>
              </li>
            </template>
            <li
              @click="next()"
              class="page-item"
            >
              <a
                class="page-link"
                href="javascript:void(0)"
              >
                <i class="icon ion-ios-arrow-forward" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </template>

    <div
      class="modal"
      id="assetCreationConfirmationModal"
    >
      <div
        class="modal-dialog modal-sm"
        role="document"
      >
        <div class="modal-content modal-content-demo">
          <div class="modal-body">
            <p>You are about to create an Asset. Are you sure?</p>
          </div>
          <div class="modal-footer">
            <button
              @click="createAsset"
              class="btn btn-indigo rounded"
              data-dismiss="modal"
              type="button"
            >Yes</button>
            <button
              class="btn btn-light rounded"
              data-dismiss="modal"
              type="button"
            >No</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "Assets",
  props: { catalogid: String },
  computed: {
    ...mapGetters(["currentUser"])
  },
  data() {
    return {
      catalog: null,
      assets: [],
      pageSizes: [5, 10, 20, 50],
      pageSize: localStorage.getItem("pageSize") || 5,
      page: 0,
      pages: 1,
      pageInfo: {
        page: 0,
        per_page: 5
      }
    };
  },
  watch: {
    page: function (nv, ov) {
      this.getAssets();
    },
    pageSize: function (nv, ov) {
      localStorage.setItem("pageSize", nv);
      this.page = 0;
      this.getAssets();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.getCatalogs();
      await this.getAssets();
    },
    async getCatalogs() {
      try {
        let reply = await this.$assetregistry.get(`/asset-registry/catalogs`);
        let catalogs = reply.data ? reply.data.catalogs : [];
        this.catalog = _.find(catalogs, { catalog: this.catalogid }).name;
      } catch (e) {

      }
    },
    async getAssets() {
      try {
        this.paginate();
        let reply = await this.$assetregistry.get(`/asset-registry/catalogs/${this.catalogid}/assets`, { params: this.pageInfo });
        this.assets = reply.data ? reply.data.assets : [];
        this.pages = reply.data ? Math.ceil(reply.data.total / this.pageSize) : 1;

      } catch (e) {
      }
    },
    async createAsset() {
      if (typeof this.catalogid !== 'undefined') {
        await this.$assetregistry.post(`/asset-registry/catalogs/${this.catalogid}/assets`, { owner: this.currentUser.public_key });
        this.$toastr.s("Asset Successfully created!", 'Success');
        this.getAssets();
      } else {

      }
    },
    paginate() {
      this.pageInfo.page = this.page;
      this.pageInfo.per_page = this.pageSize;
    },
    next() {
      if (this.page + 1 < this.pages) {
        this.page++;
      }
    },
    prev() {
      if (this.page !== 0) {
        this.page--;
      }
    },
    goToPage(n) {
      this.page = n;
    }
  }
}
</script>

<style scoped>
.masked {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.custom-margin {
  margin-top: 24px !important;
  margin-left: -25px !important;
}
</style>